<template>
  <div class="principal">
    <div class="container">
      <b-row
        cols="12"
        class="titlename"
      >
        Configuración Rol de Usuario
      </b-row>
    </div>
    <b-row>
      <b-col
        cols="3"
      >
        <nav class="navbar">
          <ul class="nav navbar-nav">
            <li class="nav-item-active">
              <router-link
                to="/clientes/usuarios/RolUsuario"
                class="nav-link"
              > Datos personales </router-link>
            </li>
            <li class="nav-item-active">
              <router-link
                to="/clientes/usuarios/Configuracion-Usuario"
                class="nav-link"
              > Configuración </router-link>
            </li>
          </ul>
        </nav>
      </b-col>
      <form
        action=""
        class="formul"
      >
        <b-col
          cols="6"
          class="formulario"
        >
          <label for="">Correo</label><br>
          <b-form-input
            type="email"
            placeholder=""
          /><br>
          <label for="">Contraseña</label><br>
          <b-form-input
            type="password"
            placeholder=""
          />
          <br>
          <label for="">Empresa</label><br>
          <b-form-input
            type="text"
          /><br>
          <label for="">N. Usuarios Permitidos</label><br>
          <b-form-input
            type="number"
          /><br>
        </b-col>
        <b-col
          cols="6"
        >
          <label for="">Usuario</label><br>
          <b-form-input
            v-model="text"
            placeholder=""
          /><br>
          <label for="">Confirmar contraseña</label><br>
          <b-form-input
            type="password"
            placeholder=""
          />
          <br>
          <label for="">Areas Asignadas</label><br>
          <b-form-select
            v-model="selected"
            :options="options"
          /><br><br>
          <label for="">N. de Sedes Permitidas</label><br>
          <b-form-select
            v-model="selected2"
            :options="total"
          /><br>
        </b-col>
      </form>
    </b-row>
    <div>
      <hr>
      <h3>Modulos Habilitados</h3>
      <hr>
    </div>
    <b-row>
      <b-col
        cols="6"
        class="accion"
      >
        <ul
          v-for="Lista, ini in listas"
          :key="ini"
        >
          <li> {{ Lista.items }} </li>
        </ul>
        <div
          class="boton btn-warning"
        >
          <feather-icon
            class="mr-50"
            icon="arrow-left"
          />
          <router-link to="/clientes/usuarios/RolUsuario">
            <span class="">Atras</span>
          </router-link>
        </div>
      </b-col>
      <b-col
        cols="6"
        class="accion"
      >
        <div
          class="boton btn-primary"
        >
          <feather-icon
            class="mr-50"
            icon="long-arrow-alt-lefticon"
          />
          <span class="">Guardar</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
    BRow, BCol, BFormSelect, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
      BRow,
      BCol,
      BFormSelect,
      BFormInput,
	},
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Seleccione area' },
        { value: 'a', text: 'Baño ' },
        { value: 'b', text: 'Habitacion' },
      ],
      selected2: null,
      total: [
        { value: null, text: 'Cantidad de Sedes' },
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
      ],
      listas: [
        { items: 'Modulo gestion de dieta' },
        { items: 'Modulo gestion de Paciente' },
        { items: 'Modulo gestion de Recursos Humanos' },
        { items: 'Modulo gestion de Medicamentos' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
  .principal{
    background-color: rgb(255, 255, 255);
  }
  .titlename{
    font-size: 22px;
    color: rgb(241, 241, 241);
    margin-left: 5px;
  }
  .container{
    background-color: rgb(176, 85, 236);
    padding: 5px;
  }
  .nav-link{
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: bolder;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    transition: 0.8s all;
    border-radius: 5px;
    border-width: 25px;
  }
  .nav-link:hover{
    background-color: blueviolet;
    color: black;
    padding: 6px;
  }
  .navbar{
    background-color:rgb(255, 255, 255);
    margin-top: 10px;
  }
  .formul{
    display: flex;
    margin-top: 10px;
    background-color: white;
    width: 70%;
  }
  .formula{
    background-color: white;
    width: 70%;
  }
  hr{
    text-align:end;
    width: 60%;
    border-top: 1px solid black;
  }
  h3{
    text-align: center;
    text-decoration-color: none;
    color: black;
  }
  .fondo{
    background-color: rgb(205, 199, 209);
    text-align: end;
  }
  .accion{
    text-align: center;
    font-size: 18px;
    font-weight: bolder;
    color: black;
  }
  ul{
    list-style:none
  }
  li{
    color: black;
    font-size: 15px;
  }
  .boton{
    width: 200px;
    text-align: center;
    margin-left: 160px;
    margin-bottom: 50px;
    padding: 8px;
  }
  label{
    font-size: 15px;
  }
  span{
    color: white;
  }
</style>
