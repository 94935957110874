var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-card-code',{staticClass:"col-sm-12",attrs:{"title":"Información general de la sede"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nombre de la sede")]),_c('validation-provider',{attrs:{"rules":"required","name":"Nombre de la sede"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nombre de la sede"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Código de habitacíon")]),_c('validation-provider',{attrs:{"rules":"required","name":"Código de habilitación"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Código de habilitación"},model:{value:(_vm.room),callback:function ($$v) {_vm.room=$$v},expression:"room"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Dirección")]),_c('validation-provider',{attrs:{"rules":"required","name":"direccion"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Dirección"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Latitud")]),_c('validation-provider',{attrs:{"rules":"required","name":"Latitud"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Latitud"},model:{value:(_vm.latitud),callback:function ($$v) {_vm.latitud=$$v},expression:"latitud"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Longitud")]),_c('validation-provider',{attrs:{"rules":"required","name":"Longitud"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Longitud"},model:{value:(_vm.longitud),callback:function ($$v) {_vm.longitud=$$v},expression:"longitud"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-card-code',{staticClass:"col-sm-12",attrs:{"title":"Información de contacto"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nombre del contacto")]),_c('validation-provider',{attrs:{"rules":"required","name":"Nombre del contacto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Nombre del contacto"},model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Teléfono")]),_c('validation-provider',{attrs:{"rules":"required|min:10","name":"Min Character"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Télefono"},model:{value:(_vm.contactPhone),callback:function ($$v) {_vm.contactPhone=$$v},expression:"contactPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Celular")]),_c('validation-provider',{attrs:{"rules":"required|min:10","name":"Min Character"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Celular"},model:{value:(_vm.contactCellPhone),callback:function ($$v) {_vm.contactCellPhone=$$v},expression:"contactCellPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('p')])],1)],1),_c('b-form-group',[_c('label',[_vm._v("Correo electrónico")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Correo electrónico"},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"type":"button","variant":"primary","disabled":_vm.isBusy},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[(_vm.isBusy)?_c('b-spinner',{attrs:{"small":""}}):_c('v-icon',{attrs:{"name":"save"}}),_vm._v(" Guardar ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }