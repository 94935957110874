<template>
  <validation-observer ref="simpleRules">
    <b-form>
      <b-row>
        <b-col sm="6">
          <b-card-code
            title="Información general de la sede"
            class="col-sm-12"
          >
            <!---Información general de la sede -->
            <!--Nombre de la sede-->
            <b-col md="12">
              <b-form-group>
                <label>Nombre de la sede</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Nombre de la sede"
                >
                  <b-form-input
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nombre de la sede"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--Codigo de habitacion-->

              <b-form-group>
                <label>Código de habitacíon</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Código de habilitación"
                >
                  <b-form-input
                    v-model="room"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Código de habilitación"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--dirección-->
              <b-form-group>
                <label>Dirección</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="direccion"
                >
                  <b-form-input
                    v-model="address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Dirección"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--latitud-->
              <b-form-group>
                <label>Latitud</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Latitud"
                >
                  <b-form-input
                    v-model="latitud"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Latitud"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--longitud-->
              <b-form-group>
                <label>Longitud</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Longitud"
                >
                  <b-form-input
                    v-model="longitud"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Longitud"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-card-code>
        </b-col>

        <!---Información general del contacto -->
        <!--  This field is required-->
        <b-col sm="6">
          <b-card-code
            title="Información de contacto"
            class="col-sm-12"
          >
            <b-col md="12">
              <b-form-group>
                <label>Nombre del contacto</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Nombre del contacto"
                >
                  <b-form-input
                    v-model="contactName"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nombre del contacto"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label>Teléfono</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:10"
                  name="Min Character"
                >
                  <b-form-input
                    v-model="contactPhone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Télefono"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label>Celular</label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|min:10"
                  name="Min Character"
                >
                  <b-form-input
                    v-model="contactCellPhone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Celular"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-row>
                  <b-col sm="12">
                    <p />
                  </b-col>
                </b-row>
              </b-form-group>
              <!-- Must be a valid email -->
              <b-form-group>
                <label>Correo electrónico</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="emailValue"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Correo electrónico"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-card-code>
        </b-col>

        <!-- submit button -->
        <b-col cols="12">
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy"
            @click.prevent="validationForm"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
	required, email, min,
} from '@validations'
import CRUDMixin from '@/mixins/CRUDMixin'

export default {
	components: {
		BCardCode,
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
	},
  mixins: [CRUDMixin],
  inject: ['brandsRepository'],
	data() {
		return {
      formData: {
        name: '',
        room: '',
        address: '',
        latitud: '',
        longitud: '',
        contactName: '',
        contactPhone: '',
        contactCellPhone: '',
        emailValue: '',
        min,
        required,
        email,
      },
      repository: 'brandsRepository',
      isBusy: false,
		}
	},
	methods: {
		validationForm() {
			this.$refs.simpleRules.validate().then(async success => {
				if (success) {
          await this.saveData()
            this.$router.push('/empresa')
				}
			})
		},
	},
}
</script>
