<template>
  <div class="principal">
    <div class="container">
      <b-row
        cols="12"
        class="titlename"
      >
        Informaciòn General del Usuario
      </b-row>
    </div>
    <b-row>
      <b-col
        cols="3"
      >
        <nav class="navbar">
          <ul class="nav navbar-nav">
            <li class="nav-item-active">
              <router-link
                to="/clientes/usuarios/RolUsuario"
                class="nav-link"
              > Datos personales </router-link>
            </li>
            <li class="nav-item-active">
              <router-link
                to="/clientes/usuarios/Configuracion-Usuario"
                class="nav-link"
              > Configuración </router-link>
            </li>
          </ul>
        </nav>
      </b-col>
      <form
        action=""
        class="formul"
      >
        <b-col
          cols="6"
          class="formulario"
        >
          <label for="">Rol de Usuario</label><br>
          <b-form-input
            v-model="text"
            placeholder=""
          /><br>
          <label for="">Apellido</label><br>
          <b-form-input
            v-model="text"
            placeholder=""
          /><br>
          <label for="">Telefono</label><br>
          <b-form-input
            v-model="myTest"
            type="number"
            max="1"
          /><br>
          <label for="">Pais</label><br>
          <b-form-select
            v-model="selected2"
            :options="pais"
          /><br>
        </b-col>
        <b-col
          cols="6"
        >
          <label for="">Nombre</label><br>
          <b-form-input
            v-model="text"
            placeholder=""
          /><br>
          <label for="">Fecha de Cumnpleaños</label><br>
          <b-form-datepicker
            id="example-datepicker"
          /><br>
          <label for="">Empresa</label><br>
          <b-form-input
            v-model="text"
            placeholder=""
          /><br>
          <label for="">Municipio</label><br>
          <b-form-select
            v-model="selected"
            :options="options"
          /><br>
        </b-col>
      </form>
    </b-row>
    <div>
      <hr>
      <h3>ULTIMOS ACCESOS</h3>
      <hr>
    </div>
    <b-row>
      <b-col
        cols="6"
        class="accion"
      >
        Accion
        <hr>
        <ul
          v-for="Lista, ini in listas"
          :key="ini"
        >
          <li> {{ Lista.items }} </li>
        </ul>
        <div
          class="boton btn-warning"
        >
          <feather-icon
            class="mr-50"
            icon="BellIcon"
          />
          <span class="">Cancelar</span>
        </div>
      </b-col>
      <b-col
        cols="6"
        class="accion"
      >
        Fecha
        <hr>
        <ul
          v-for="fecha, fin in fechas"
          :key="fin"
        >
          <li>{{ fecha.items }}</li>
        </ul>
        <div
          class="boton btn-primary"
        >
          <feather-icon
            class="mr-50"
            icon="BellIcon"
          />
          <span class="">Guardar</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
    BRow, BCol, BFormSelect, BFormDatepicker, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
      BRow,
      BCol,
      BFormSelect,
      BFormDatepicker,
      BFormInput,
	},
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Escoja su municipio' },
        { value: 'a', text: 'Bolivar' },
        { value: 'b', text: 'Atlantico' },
        { value: 'c', text: 'Cundinamarca' },
        { value: 'd', text: 'Antioquia' },
        { value: 'e', text: 'Cesar' },
      ],
      selected2: null,
      pais: [
        { value: null, text: 'Escoja su nacionalidad' },
        { value: '1', text: 'Colombia' },
      ],
      listas: [
        { items: 'Entrada' },
        { items: 'Salida' },
        { items: 'Entrada' },
        { items: 'Entrada' },
      ],
      fechas: [
        { items: '10/11/2020 10:34' },
        { items: '10/11/2020 10:54' },
        { items: '10/11/2020 10:54' },
        { items: '10/11/2020 10:54' },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
  .principal{
    background-color: rgb(255, 255, 255);
  }
  .titlename{
    font-size: 22px;
    color: rgb(241, 241, 241);
    margin-left: 5px;
  }
  .container{
    background-color: rgb(176, 85, 236);
    padding: 5px;
  }
  .nav-link{
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: bolder;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    transition: 0.8s all;
    border-radius: 5px;
    border-width: 25px;
  }
  .nav-link:hover{
    background-color: blueviolet;
    color: black;
    padding: 6px;
  }
  .navbar{
    background-color:rgb(255, 255, 255);
    margin-top: 10px;
  }
  .formul{
    display: flex;
    margin-top: 10px;
    background-color: white;
    width: 70%;
  }
  .formula{
    background-color: white;
    width: 70%;
  }
  hr{
    text-align:end;
    width: 60%;
    border-top: 1px solid black;
  }
  h3{
    text-align: center;
    text-decoration-color: none;
    color: black;
  }
  .fondo{
    background-color: rgb(205, 199, 209);
    text-align: end;
  }
  .accion{
    text-align: center;
    font-size: 18px;
    font-weight: bolder;
    color: black;
  }
  ul{
    list-style:none
  }
  li{
    color: black;
  }
  .boton{
    width: 200px;
    text-align: center;
    margin-left: 160px;
    margin-bottom: 50px;
    padding: 8px;
  }
  label{
    font-size: 15px;
  }
</style>
